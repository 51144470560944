<template>
    <router-view></router-view>
</template>

<script>
export default {
    name: "App",
};
</script>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}

.fade-comps {
    animation-name: fade;
    animation-duration: .5s;
}

.bg-green-enelav {
    background-color: #7bddaa;
}

@keyframes fade {
    from {
        opacity: 0;
    } to {
        opacity: 1;
    }
}
</style>
