<template>
    <h1 class="text-start h4 text-dark fade-comps">
        Seleccione un curso para mirar los detalles de su plano y pagos
    </h1>
    <div class="row mt-5 fade-comps" v-if="cursosAtivos.length > 0">
        <div
            class="col-12 col-md-6 col-lg-4 mb-3"
            v-for="(curso, idx) in cursosAtivos"
            :key="idx"
        >
            <div class="shadow p-2 bg-green-enelav rounded text-dark">
                <p class="text-start mb-3">
                    <strong>{{ curso.grupo.nombre }}</strong> <br />
                    Turno {{ turnoDesc[curso.grupo.turno - 1] }}
                </p>
                <div class="d-grid">
                    <button class="btn btn-dark text-white" @click="$emit('update:cursoSeleccionado', { cursoGrupo: curso.grupo, planPago: curso.planPago })">Ver</button>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="cursosAtivos.length === 0 && !cargandoCursos" class="text-muted">No tenes cursos ativos...</div>
    <div v-else class="fade-comps">
        <div class="spinner-border m-5 mb-0" role="status"></div>
        <br />
        <span>Cargando cursos...</span>
    </div>
</template>

<script>
export default {
    props: {
        cursosAtivos: Array,
        cursoSeleccionado: String,
        cargandoCursos: Boolean
    },
    emits: ['update:cursoSeleccionado'],
    data: () => ({
        turnoDesc: ['Mañana', 'Tarde', 'Vespertino']
    })
};
</script>