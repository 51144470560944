<template>
    <div class="container-fluid py-3 text-light bg-dark">
        <header class="container">
            <div class="row">
                <div class="col-9">
                    <h1 class="text-start h2 mb-0">
                        Hola, {{ userSession.nombre }}.
                    </h1>
                </div>
                <div class="col text-end">
                    <button
                        class="btn btn-secondary align-items-center"
                        @click="logout()"
                    >
                        <div class="d-flex">
                            <img
                                src="https://img.icons8.com/ios-filled/24/7bddaa/exit.png"
                            />
                        </div>
                    </button>
                </div>
            </div>
        </header>
    </div>
    <main class="container">
        <div class="rounded bg-light shadow-sm p-3 mb-4">
            <CardsCursos
                :cursosAtivos="cursosAtivos"
                :cargandoCursos="cargandoCursos"
                v-model:cursoSeleccionado="cursoSeleccionado"
                v-if="!cursoSeleccionado"
            />
            <Pagos 
                :cursoInfos="cursoSeleccionado"
                :dateSystemUpdate="dateSystemUpdate"
                @backToCourses="cursoSeleccionado = null"
                v-else
            />
        </div>
    </main>
    <footer class="container"></footer>
</template>

<script>
import api from "../../services/api";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import CardsCursos from "../../components/Cursos.vue";
import Pagos from "../../components/Pagos.vue";
export default {
    components: {
        CardsCursos,
        Pagos,
    },
    setup() {
        // General
        onMounted(() => {
            userSession.value = JSON.parse(localStorage.getItem("userInfos"));

            if (!userSession.value) return router.push("/login");
        });
        const router = useRouter();

        function logout() {
            window.localStorage.clear();
            router.push("/login");
        }

        // Cursos del Usuario
        const userSession = ref({});
        const cursosAtivos = ref([]);
        const cargandoCursos = ref(false)
        const dateSystemUpdate = ref(true);

        onMounted(async () => {
            cargandoCursos.value = true;

            const { data } = await api.getStudent(
                userSession.value.id,
                userSession.value.accessToken
            );

            const systemUpdate = await api.getSystemInfos(
                userSession.value.id,
                userSession.value.accessToken
            );

            dateSystemUpdate.value = systemUpdate.data.response;

            cursosAtivos.value = data.plan_grupo.filter(
                (plan) => plan.grupo.borrado !== "1" && !plan.grupo.nombre.toUpperCase().includes("ABANDONOS")
            );
            cargandoCursos.value = false;
        });

        // Pagos del curso

        let cursoSeleccionado = ref(null);

        return { userSession, cursosAtivos, logout, cursoSeleccionado, cargandoCursos, dateSystemUpdate };
    },
};
</script>

<style scoped>
header {
    padding-bottom: 100px !important;
}

main {
    margin-top: -80px;
}
</style>
