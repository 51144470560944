<template>
    <main class="container d-flex align-items-center justify-content-center">
        <div class="row">
            <div class="card col-12 col-lg-6 shadow-lg mx-auto pt-1">
                <div class="card-body">
                    <h3 class="card-title mb-4 text-start">
                        Central del estudiante
                    </h3>
                    <div class="d-grid">
                        <input
                            type="text"
                            class="form-control mb-3"
                            placeholder="Usuario"
                            v-model="loginInfos.cedula"
                        />
                        <input
                            type="password"
                            class="form-control mb-4"
                            placeholder="Contraseña"
                            v-model="loginInfos.password"
                        />
                        <button class="btn btn-primary" @click="login">
                            Login
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import api from "../../services/api";
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";
export default {
    name: "Login",
    setup() {
        const router = useRouter();
        const toast = useToast();

        let loginInfos = ref({
            cedula: null,
            password: null,
        });

        async function login() {
            const { data } = await api.login(loginInfos.value);

            if (data.response !== "err") {
                window.localStorage.setItem(
                    "userInfos",
                    JSON.stringify(data.response)
                );
                router.push("/dashboard");
            } else {
                toast.error("Usuario e/o contraseña incorrectos");
                loginInfos.value.cedula = null;
                loginInfos.value.password = null;
            }
        }

        return { loginInfos, login };
    },
};
</script>

<style scoped>
main {
    height: 100vh;
    width: 100%;
}

.row {
    width: 100%;
}

.card {
    max-width: 800px;
    border: none;
}
</style>
