// plugins
import { createApp } from 'vue'
import App from './App.vue'
import BootstrapVue3 from 'bootstrap-vue-3'
import Toast, { POSITION } from 'vue-toastification'
import { createRouter, createWebHashHistory } from 'vue-router'

// styles
import "vue-toastification/dist/index.css";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import routes from '../router.js'

// configs
const router = createRouter({
    history: createWebHashHistory(),
    routes
})
const options = {
    position: POSITION.TOP_CENTER,
    timeout: 3000
};
const app = createApp(App);

// integrate
app.use(BootstrapVue3);
app.use(Toast, options)
app.use(router);
app.mount('#app');
