<template>
    <header class="fade-comps bg-green-enelav text-dark p-3 shadow rounded mb-4">
        <div class="row align-items-center">
            <div class="col-2 text-start">
                <img
                    class="back-btn"
                    src="https://img.icons8.com/ios-filled/30/212529/back-arrow.png"
                    @click="$emit('backToCourses')"
                />
            </div>
            <div class="col-8">
                <h1 class="text-center h4 mb-0">Estado de cuenta</h1>
            </div>
            <div class="col-2" />
        </div>

        <section v-if="resultadoPagos" class="mt-3">
            <h2 class="text-center h4 mb-0">
                <strong>{{ cursoInfos.cursoGrupo.nombre }}</strong>
            </h2>
            <h3 class="h5 mb-1 mt-3 text-center">
                Inicio plan pago: <br v-if="windowWitdh < 395">
                {{ formatDate(resultadoPagos.fechaPrimerPago) }}
            </h3>
            <h3 class="h5 mb-0 text-center">
                Fecha actualización: <br v-if="windowWitdh < 395">
                {{ formatDate(dateSystemUpdate.fechaPago) }}
            </h3>
        </section>
        <div v-else class="fade-comps text-dark mt-4 mb-1">
            <div class="spinner-border" role="status"></div>
            <br />
            <span>Cargando informaciones...</span>
        </div>
    </header>
    <main class="mt-4 fade-comps text-start" v-if="resultadoPagos">
        <header class="p-1 border-bottom mb-2">
            <p class="lead mb-0">
                {{
                    `Plan: ${ cursoInfos.planPago.nombre } - ${ cursoInfos.planPago.tipo }`
                }}
            </p>
            <p class>
                {{ "Monto del plan: " + formatMoney(Number(cursoInfos.planPago.monto)) }}
            </p>
        </header>

        <div class="row">
            <article class="accordion col-lg-6 col-12" id="accordionFlushOne">
                <p class="lead mb-2">
                    Monto total pago: <br> <span class="text-success">{{ formatMoney(resultadoPagos.pagos[0].total_payment) }}</span>
                </p>
                <p class="lead mb-2">
                    Cuotas ya pagas: {{ resultadoPagos.cuotaPaga.toLocaleString('pt-BR') }} / {{ cursoInfos.planPago.cuotas }}
                </p>
                <div class="accordion-item mb-2">
                    <h2 class="accordion-header" id="flush-headingOne">
                        <button
                            class="accordion-button collapsed text-dark"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseOne"
                            aria-expanded="false"
                            aria-controls="flush-collapseOne"
                        >
                            Lista de pagos
                        </button>
                    </h2>
                    <div
                        id="flush-collapseOne"
                        class="accordion-collapse collapse show"
                        aria-labelledby="flush-headingOne"
                        data-bs-parent="#accordionFlushOne"
                    >
                        <div class="accordion-body">
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Movimiento</th>
                                        <th>Costo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(pago, idx) in pagos" :key="idx">
                                        <td><p>{{ formatDate(pago.fechaPago) }}</p></td>
                                        <td><p>{{ pago.movimiento }}</p></td>
                                        <td><p>{{ formatMoney(Number(pago.monto)) }}</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <!-- <p class="lead">
                    Monto restante del plan: <br>
                    <span class="text-muted">
                        {{
                            formatMoney((cursoInfos.planPago.monto - resultadoPagos.pagos[0].total_payment))
                        }}
                    </span>
                </p> -->
            </article>

            <article class="accordion pb-4 mt-2 mt-lg-0 col-lg-6 col-12" id="accordionFlushTwo">
                <!-- <p class="lead mb-2">
                    Monto restante de recargos generados: <br> <span class="text-danger">{{ formatMoney(recargos.reduce((monto, recargo) => monto += recargo.recharge_cierre, 0)) }}</span>
                </p>
                <p class="lead mb-2">
                    Cantidad:
                    {{
                        recargos.length
                    }}
                </p> -->
                <div class="accordion-item" :style="hasMarginTop()">
                    <h2 class="accordion-header" id="flush-headingTwo">
                        <button
                            class="accordion-button collapsed text-dark"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#flush-collapseTwo"
                            aria-expanded="false"
                            aria-controls="flush-collapseTwo"
                        >
                            Lista de recargos generados a la fecha
                        </button>
                    </h2>
                    <div
                        id="flush-collapseTwo"
                        class="accordion-collapse collapse show"
                        aria-labelledby="flush-headingTwo"
                        data-bs-parent="#accordionFlushTwo"
                    >
                        <div class="accordion-body">
                            <table class="w-100">
                                <thead>
                                    <tr>
                                        <th>Fecha</th>
                                        <th>Recargo</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(recargo, idx) in recargos" :key="idx">
                                        <td><p>{{ formatDate(recargo.fechaPago) }}</p></td>
                                        <td><p>{{ formatMoney(Number(recargo.recharge_cierre)) }}</p></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </article>

            <article class="col-12 mt-2">
                <!-- <p class="lead mb-2">
                    Deuda total: <br>
                    <span class="text-warning">
                        {{
                            formatMoney(recargos.reduce((monto, recargo) => monto += recargo.recharge_cierre, 0)
                            + (cursoInfos.planPago.monto - resultadoPagos.pagos[0].total_payment))
                        }}
                    </span>
                </p> -->
                <p class="mb-2">
                    Próximo vencimiento: {{ nextPayment }}<br>
                </p>
            </article>
        </div>
    </main>
</template>

<script>
import { onMounted, computed, ref } from "vue";
import api from "../services/api";
export default {
    props: {
        cursoInfos: Object,
        dateSystemUpdate: Object
    },
    emits: ["backToCourses"],
    setup(props) {
        let session = JSON.parse(localStorage.getItem("userInfos"));
        let resultadoPagos = ref(null);
        let nextPayment = ref('');
        let windowWitdh = ref(null);

        onMounted(async () => {
            const { data } = await api.getPagosCurso(
                session.id,
                props.cursoInfos.cursoGrupo.id,
                session.accessToken
            );

            data.pagosTotal.sort((a, b) => {
                const info1 = new Date(a.fechaPago)
                const info2 = new Date(b.fechaPago)

                return info1 > info2 ? 1 : info2 > info1 ? -1 : 0;
            })

            resultadoPagos.value = data
            const totalQuotasPayed = resultadoPagos?.value?.cuotaPaga;
            const totalPlanQuotas = parseInt(props?.cursoInfos?.planPago?.cuotas, 10);
            if (totalQuotasPayed > totalPlanQuotas) {
                resultadoPagos.value.cuotaPaga = totalPlanQuotas;
            }

            windowWitdh.value = window.innerWidth;
            window.addEventListener('resize', () => windowWitdh.value = window.innerWidth);

            const date = new Date();
            const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            const formatedMonth = (lastDayOfMonth.getMonth() + 1) > 9 ? lastDayOfMonth.getMonth() + 1 : ('0' + (lastDayOfMonth.getMonth() + 1))
            nextPayment.value = `${lastDayOfMonth.getDate()}/${formatedMonth}/${lastDayOfMonth.getFullYear()}`
        });

        const getMonthsDifference = (startDate, endDate) => {
            return endDate.getMonth() - startDate.getMonth() + 12 * (endDate.getFullYear() - startDate.getFullYear());
        }

        const penaltyShouldBeCharged = (p, totalPaid) => {
            const monthsToCalc = getMonthsDifference(new Date(p.firstPaymentDate), new Date(p.fechaPago)) + 1;
            const monthlyFee = +(props?.cursoInfos?.planPago?.costo);
            const expectedValuePaid = monthsToCalc * monthlyFee;
            const totalMonthsToCalc = getMonthsDifference(new Date(p.contractFirstPaymentDate), new Date(p.fechaPago)) + 1;
            const expectedTotalValuePaid = totalMonthsToCalc * monthlyFee;
            let isPenalty = false;
            if (p.currentTotalPayed < expectedTotalValuePaid) {
                if (totalPaid < expectedValuePaid) {
                    isPenalty = true;
                    return isPenalty;
                }
            }
            return isPenalty;
        }

        const filterRealPenalties = (group) => {
            const realPenalties = [];
            let totalPaid = 0;
            group.forEach((p) => {
                const isPenalty = p.recharge_cierre && p.recharge_cierre > 0;
                let shouldBeCharged = false;
                totalPaid += +(p.monto);
                if (isPenalty) {
                    shouldBeCharged = penaltyShouldBeCharged(p, totalPaid);
                }

                if (shouldBeCharged) realPenalties.push(p);
            });

            return realPenalties;
        }

        const recargos = computed(() => {
            const total = resultadoPagos?.value?.pagosTotal;
            const firstPaymentDate = total?.find((p) => p.first_payment).fechaPrimerPago;
            const filteredTotal = total?.filter((p) => !p.first_payment);
            filteredTotal.sort((a, b) => {
                return new Date(a.fechaPago) - new Date(b.fechaPago);
            });
            const feesByGroup = {};
            let group = 0;
            let currentTotalPayed = 0;
            filteredTotal.forEach((p, i) => {
                currentTotalPayed += +(p.monto);
                const current = {
                    fechaPago: p.fechaPago,
                    monto: p.monto,
                    recharge_cierre: p.recharge_cierre,
                    tipo: p.tipo,
                    firstPaymentDate,
                    currentTotalPayed,
                    contractFirstPaymentDate: firstPaymentDate,
                };

                if (!feesByGroup[group]) {
                    current.firstPaymentDate = p.fechaPago;
                    feesByGroup[group] = [current];
                } else {
                    if (group !== 0) {
                        current.firstPaymentDate = feesByGroup[group][0].firstPaymentDate;
                    }
                    feesByGroup[group].push(current);
                }

                if (p.monto === 0 && filteredTotal[i + 1] && filteredTotal[i + 1].monto > 0) {
                    group += 1;
                }
            });

            let realPenalties = Object.values(feesByGroup).map((g) => filterRealPenalties(g));
            realPenalties = realPenalties.reduce((acc, p) => ([...acc, ...p]), []);

            return realPenalties;
        });

        const pagos = computed(() => {
            return resultadoPagos.value.pagosTotal.filter(pago => 
                parseInt(pago.monto) > 0
                && !pago.recharge_cierre
                && !pago.first_payment
            );
        })

        const formatMoney = function (value) {
            return value.toLocaleString('en-US', {style: 'currency', currency: 'USD'})
        }

        const formatDate = (value) => {
            if (!value) return;
            const currentDate = value.split(' ')[0];
            const splittedDate = currentDate.split('-');
            const d = splittedDate[2];
            const m = splittedDate[1];
            const y = splittedDate[0];

            return `${d}/${m}/${y}`;
        }

        const hasMarginTop = () => {
            if (windowWitdh.value > 991) return 'margin-top: 106px'; // 60 for p1 + 8 margin bottom + 30 for p2 + 8 margin bottom
            return '';
        }

        return { resultadoPagos, formatMoney, formatDate, recargos, pagos, nextPayment, windowWitdh, hasMarginTop };
    },
};
</script>

<style scoped>
.back-btn {
    border-radius: 1000px;
    transition: 0.3s;
}

.back-btn:hover {
    transform: scale(1.1);
}
</style>
