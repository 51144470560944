import axios from 'axios'

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL
})

export default {
    headers: function (token, userId) { 
        return { 
            headers: { 
                'app': 'public',
                'access-token': token,
                'escuela': 1,
                'user': userId
            }
        } 
    },

    login: function (loginInfos) {
        return api.post('/estudiante/login', loginInfos)
    },

    getStudent: function (studentId, token) {
        return api.get(`/estudiante/${studentId}`, this.headers(token, studentId))
    },

    getPagosCurso: function (studentId, groupId, token) {
        return api.get(`/pago/aux/estudiantes/${studentId}?group=${groupId}`, this.headers(token, studentId))
    },

    getSystemInfos: function (studentId, token) {
        return api.get(`/pago/last/1`, this.headers(token, studentId))
    }
}